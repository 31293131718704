import format from 'date-fns/format';
import compareDesc from 'date-fns/compareDesc';

const sortEntries = (a, b) => compareDesc(a.dateObj, b.dateObj);

export const addWorkoutEntry = (state, workoutEntry, dateObj = new Date()) => {
  const formattedDate = format(dateObj, 'dd/MM/yyyy');

  // no existing same date
  if (!state.entriesByDate.find((e) => e.date === formattedDate)) {
    return {
      entriesByDate: [
        ...state.entriesByDate,
        {
          date: formattedDate,
          dateObj,
          entries: [{ id: 0, ...workoutEntry }],
        },
      ].sort(sortEntries),
    };
  }

  // date exists
  const existingEntry = state.entriesByDate.filter(
    (e) => e.date === formattedDate
  )[0];

  return {
    entriesByDate: [
      ...state.entriesByDate.filter((e) => e.date !== formattedDate),
      {
        ...existingEntry,
        entries: [
          { id: existingEntry.entries.length, ...workoutEntry },
          ...existingEntry.entries,
        ],
      },
    ].sort(sortEntries),
  };
};

export const removeWorkoutEntry = (state, id, formattedDate) => {
  const existingEntry = state.entriesByDate.filter(
    (e) => e.date === formattedDate
  )[0];

  return {
    entriesByDate: [
      ...state.entriesByDate.filter((e) => e.date !== formattedDate),
      {
        ...existingEntry,
        entries: existingEntry.entries.filter((e) => e.id !== id),
      },
    ].sort(sortEntries),
  };
};
