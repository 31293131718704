import { createSlice } from '@reduxjs/toolkit';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    theme: localStorage.getItem('theme') || 'light',
    unit: 'KG',
  },
  reducers: {
    toggleTheme: (state) => {
      state.theme = state.theme === 'light' ? 'dark' : 'light';
      localStorage.setItem('theme', state.theme);
    },
    toggleUnit: (state) => {
      state.unit = state.unit === 'KG' ? 'Lb' : 'KG';
    },
  },
});

export const { toggleTheme, toggleUnit } = appSlice.actions;

export const selectTheme = (state) => state.app.theme;
export const selectUnit = (state) => state.app.unit;

export default appSlice.reducer;
