import React from 'react';
import { Grommet } from 'grommet';
import { useSelector } from 'react-redux';
// import theme from './theme';
import { dark, light } from 'grommet/themes';
import logo from './assets/logo.svg';
import { selectTheme } from './app/appSlice';

import Workouts from './features/workouts';
import Footer from './features/footer';
import './App.css';

function App() {
  const selectedTheme = useSelector(selectTheme);

  return (
    <Grommet className="App" theme={selectedTheme === 'dark' ? dark : light}>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <Workouts />
      <Footer />
    </Grommet>
  );
}

export default App;
