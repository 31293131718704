import React from 'react';
import { Box, List } from 'grommet';
import { Trash } from 'grommet-icons';
import styled from 'styled-components';

const StyledBox = styled(Box)`
  white-space: pre;
`;

const PointerPox = styled(Box)`
  cursor: pointer;
`;

const getPlural = (n) => (n && n > 1 ? 's' : '');

export const WorkoutsList = ({ date, entries, remove }) => (
  <Box alignContent="center" alignSelf="center" flex="grow" width="large">
    <List
      children={(data) => (
        <Box direction="row">
          <Box direction="row-responsive" fill justify="between">
            <Box width="small">
              <b>{data.workoutName}</b>
            </Box>
            <Box direction="row">
              <StyledBox direction="row">
                <b>{data.sets}</b> set{getPlural(data.sets)} x{' '}
              </StyledBox>
              <StyledBox direction="row">
                <b>{data.reps}</b> rep{getPlural(data.reps)} @{' '}
              </StyledBox>
              <Box direction="row">
                <b>{data.weight}</b> KG
              </Box>
            </Box>
          </Box>
          <PointerPox
            justify="center"
            margin={{ left: 'large' }}
            width="xxxsmall"
          >
            <Trash onClick={() => remove(data.id, date)} alt="remove entry" />
          </PointerPox>
        </Box>
      )}
      data={entries}
      primaryKey="workoutName"
      secondaryKey="weight"
    />
  </Box>
);

export default WorkoutsList;
