import React from 'react';
import { Box } from 'grommet';
import { useSelector, useDispatch } from 'react-redux';
import { add, remove, selectEntriesDateList } from './workoutsSlice';
import WorkoutsForm from './WorkoutsForm';
import WorkoutsDateList from './WorkoutsDateList';

export function Workouts() {
  const entriesDateList = useSelector(selectEntriesDateList);
  const dispatch = useDispatch();

  return (
    <Box flex="grow">
      <WorkoutsForm add={(entry) => dispatch(add(entry))} />
      <WorkoutsDateList
        remove={(id, date) => dispatch(remove({ id, date }))}
        entriesDateList={entriesDateList}
      />
    </Box>
  );
}
