import React from 'react';
import format from 'date-fns/format';
import { Accordion, AccordionPanel, Box } from 'grommet';
import WorkoutsList from './WorkoutsList';

export const WorkoutsDateList = ({ entriesDateList, remove }) => (
  <Box alignContent="center" alignSelf="center" flex="grow" width="large">
    <Accordion margin="small" multiple>
      {entriesDateList.map(
        (e) =>
          e.entries &&
          e.entries.length > 0 && (
            <AccordionPanel
              header={
                <Box
                  align="center"
                  background="#666"
                  direction="row"
                  height="xxsmall"
                  justify="center"
                  margin={{ top: '5px' }}
                >
                  {format(e.dateObj, 'dd MMMM yyyy')}
                </Box>
              }
            >
              <WorkoutsList date={e.date} entries={e.entries} remove={remove} />
            </AccordionPanel>
          )
      )}
    </Accordion>
  </Box>
);

export default WorkoutsDateList;
