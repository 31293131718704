import React, { useState } from 'react';
import { Box, Button, Select, TextInput } from 'grommet';

const initOptions = [
  'Squat',
  'Bench press',
  'Deadlift',
  'Dumbbells',
  'Overhead press',
  'Weighted rows',
];

const validateEntry = (entry) => {
  if (entry.workoutName && entry.sets && entry.reps && entry.weight) {
    return true;
  }

  return false;
};

export const WorkoutsForm = ({ add }) => {
  const [workoutName, setWorkoutName] = useState('');
  const [reps, setReps] = useState('');
  const [sets, setSets] = useState('');
  const [weight, setWeight] = useState('');
  const [searchOptions, setSearchOptions] = useState(initOptions);

  return (
    <Box
      align="center"
      alignContent="center"
      alignSelf="center"
      direction="row-responsive"
      gap="small"
      margin="small"
      width={{ max: 'large' }}
    >
      <Box flex="grow">
        <Select
          a11yTitle="Select workout name"
          options={searchOptions}
          onChange={({ option }) => setWorkoutName(option)}
          onClose={() => setSearchOptions(initOptions)}
          onSearch={(s) => {
            if (!s) {
              setSearchOptions(initOptions);
            }

            return s
              ? setSearchOptions(
                  initOptions
                    .map((o) => o.toLowerCase().includes(s.toLowerCase()) && o)
                    .filter((o) => o)
                )
              : initOptions;
          }}
          placeholder="Select workout"
          searchPlaceholder="Type here to search"
          value={workoutName}
        />
      </Box>
      <Box direction="row" gap="small">
        <TextInput
          placeholder="Sets"
          value={sets}
          onChange={(event) => setSets(event.target.value)}
        />
        <TextInput
          placeholder="Reps"
          value={reps}
          onChange={(event) => setReps(event.target.value)}
        />
        <TextInput
          placeholder="Weight"
          value={weight}
          onChange={(event) => setWeight(event.target.value)}
        />
      </Box>
      <Button
        primary
        label="Add"
        onClick={() =>
          validateEntry({ workoutName, sets, reps, weight })
            ? add({ workoutName, sets, reps, weight })
            : null
        }
      />
    </Box>
  );
};

export default WorkoutsForm;
