import React from 'react';
import { Box, Text } from 'grommet';
import { Info } from 'grommet-icons';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { toggleTheme } from '../../app/appSlice';

const StyledBox = styled(Box)`
  color: gray;
  white-space: pre;
`;

export const Footer = () => {
  const dispatch = useDispatch();
  // const selectedTheme = useSelector(selectTheme);

  return (
    <StyledBox direction="row" justify="center" margin="small">
      <Info onClick={() => dispatch(toggleTheme())} /> - <Text>KG/Lb</Text>
    </StyledBox>
  );
};

export default Footer;
