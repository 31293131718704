import { configureStore } from '@reduxjs/toolkit';
import appReducer from './appSlice';
import workoutsReducer from '../features/workouts/workoutsSlice';

export default configureStore({
  reducer: {
    app: appReducer,
    workouts: workoutsReducer,
  },
});
