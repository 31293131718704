import { createSlice } from '@reduxjs/toolkit';
import { addWorkoutEntry, removeWorkoutEntry } from './workoutsUtils';

export const workoutsSlice = createSlice({
  name: 'workouts',
  initialState: {
    entriesByDate: [
      {
        date: '08/06/2020',
        dateObj: new Date(2020, 5, 8),
        entries: [
          {
            id: 1,
            workoutName: 'Bench press',
            sets: '5',
            reps: '5',
            weight: '65',
          },
          {
            id: 0,
            workoutName: 'Squat',
            sets: '5',
            reps: '5',
            weight: '85',
          },
        ],
      },
      {
        date: '06/06/2020',
        dateObj: new Date(2020, 5, 6),
        entries: [
          {
            id: 1,
            workoutName: 'Bench press',
            sets: '5',
            reps: '5',
            weight: '60',
          },
          {
            id: 0,
            workoutName: 'Squat',
            sets: '5',
            reps: '5',
            weight: '80',
          },
        ],
      },
    ],
  },
  reducers: {
    add: (state, action) => addWorkoutEntry(state, action.payload),
    remove: (state, action) =>
      removeWorkoutEntry(state, action.payload.id, action.payload.date),
  },
});

export const { add, remove } = workoutsSlice.actions;

export const selectEntriesDateList = (state) => state.workouts.entriesByDate;

export default workoutsSlice.reducer;
